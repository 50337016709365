import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTrash, faSearch, faEdit } from '@fortawesome/free-solid-svg-icons'
import './../investment.css';
import "react-datepicker/dist/react-datepicker.css";
import authService from './../api-authorization/AuthorizeService'
import { Modal } from "./../modals/modalConfirm";
import DatePicker from "react-datepicker";
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import DataTable from 'react-data-table-component';
export class InvoicesNotPaid extends React.Component {
    constructor(props) {
        super(props);
        const d = new Date();
        this.state = {
            loading: true,
            invoices: [],
            totalOpen:0,
            modalVisible: false,
            toRemoveId: -1,
            modalContent: "",
            handleConfirm: null,
            investmentSelect: [],
            investments: [],
            markAsPaid: -1,
            search: {
                issuedDateFrom: new Date(d.getFullYear() - 1, d.getMonth(), d.getDate(), 0, 0, 0),
                issuedDateTo: new Date(d.getFullYear(), d.getMonth() + 1, d.getDate(), 23, 59, 59),
                investment: "-1"
            }
        };
        this.remove_callBack = this.remove_callBack.bind(this);
        this.markAsPaid_callBack = this.markAsPaid_callBack.bind(this);
    }

    componentDidMount() {
        this.populateInvestmentProcData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (JSON.stringify(this.state) !== JSON.stringify(prevState) || this.props.forceRefresh == true)
            this.populateInvestmentProcData();
    }

    async populateInvestmentProcData() {
        const token = await authService.getAccessToken();
        const response = await fetch('invoice/getnotpaidinvoices', {
            method: 'POST',
            body: JSON.stringify(this.state.search),
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        });
        let res = await response.json();
        let _totalValue = res.invoices.reduce((a, b) => +a + +b.invoiceValue, 0);
        this.setState({ totalOpen:_totalValue, investments: res.invoices, loading: false, investmentSelect: res.investment });
    }

    async markAsPaid_callBack() {
        const token = await authService.getAccessToken();
        const response = await fetch('invoice/markaspaid', {
            method: 'POST',
            body: JSON.stringify(this.state.markAsPaid),
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        });
        let res = await response.json();
        this.setState({ modalVisible: false });
        this.populateInvestmentProcData();
    }

    remove(id) {
        this.setState({ handleConfirm: this.remove_callBack, modalContent: "Are you sure to delete selected invoice?", modalVisible: true, toRemoveId: id });
    }

    markAsPaid(id) {
        this.setState({ handleConfirm: this.markAsPaid_callBack, modalContent: "Are you sure to mark selected invoice as paid?", modalVisible: true, markAsPaid: id });
    }

    async remove_callBack() {
        const token = await authService.getAccessToken();
        const response = await fetch('invoice/markasremoved', {
            method: 'POST',
            body: JSON.stringify(this.state.toRemoveId),
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        });
        await response.json();
        this.setState({ modalVisible: false });
        this.populateInvestmentProcData();
        this.props.refreshTable();
    }

    hideModal = () => {
        this.setState({ modalVisible: false });
    };

    handleChangeDate(handleChangeDate, id) {
        let m = this.state.search;
        m[id] = new Date(handleChangeDate.getFullYear(), handleChangeDate.getMonth(), handleChangeDate.getDate(), 12, 0, 0);
        this.setState({
            search: m
        });
        this.populateInvestmentProcData();
    }

    handleChange(e) {
        let m = this.state.search;
        m[e.target.id] = e.target.value;
        this.setState({
            search: m
        });
        this.populateInvestmentProcData();
    }

    render() {
        const columns = [
            {
                name: 'Investment',
                cell: (invs) => invs.investment.client.name + ": " + invs.investment.description,
                sortable: true,
                wrap: true
            },
            {
                name: 'Issuer',
                selector: 'companyIssuer.name',
                sortable: true,
                wrap: true
            },
            {
                name: 'Invoice Nr.',
                selector: 'invoiceNr',
                sortable: true,
                wrap: true
            },
            {
                name: 'Type',
                cell: (invs) =>
                    invs.invoiceType == 0 ? "Invoice" : "Faktura",
                sortable: true,
                wrap: true
            },
            {
                name: 'Issued Date',
                selector: "invoiceCreatedDate",
                sortable: true,
                format: (row, i) => {
                    let d = new Date(row.invoiceCreatedDate);
                    return d.getDate() + "." + (parseInt(d.getMonth()) + 1) + "." + d.getFullYear();
                },
                wrap: true
            },
            {
                name: 'Due Date',
                selector: "dueDate",
                sortable: true,
                format: (row, i) => {
                    let d = new Date(row.dueDate);
                    return d.getDate() + "." + (parseInt(d.getMonth()) + 1) + "." + d.getFullYear();
                },
                wrap: true
            },
            {
                name: 'Value',
                sortable: true,
                wrap: true,
                cell: (inv) =>
                    inv.invoiceValue.toLocaleString('de-DE', {
                        style: 'currency',
                        currency: 'EUR',
                    })
            },
            {
                cell: (invs) =>
                    <div style={{ "textAlign": "right" }}>
                        <button className="btn btn-danger btn-sm mr-1" type="button" onClick={() => this.remove(invs.id)}><FontAwesomeIcon icon={faTrash} /></button>
                        <button className="btn btn-success btn-sm" type="button" onClick={() => this.markAsPaid(invs.id)}><FontAwesomeIcon icon={faCheck} /></button>
                    </div>
                ,
                ignoreRowClick: true,
                allowOverflow: true,
                button: false,
                right: true
            }];



        return (
            <div>
                <Modal handleClose={this.hideModal} show={this.state.modalVisible} title="Warning" content={this.state.modalContent} handleConfirm={this.state.handleConfirm} confirmText="Yes" />
                <div className="mt-4">
                    <h1>Unpaid Invoices</h1>
                    <div className="form-group row">
                        <div className="col-md-4 col-sm-12">
                            <label htmlFor="issuedDateFrom">Issued Date From</label>
                            <DatePicker selected={this.state.search.issuedDateFrom} onChange={date => {
                                this.handleChangeDate(date, "issuedDateFrom")
                            }}
                                dateFormat="dd.MM.yyyy"
                                id="issuedDateFrom" />
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <label htmlFor="issuedDateTo">Due Date To</label>
                            <DatePicker selected={this.state.search.issuedDateTo} onChange={date => {
                                this.handleChangeDate(date, "issuedDateTo")
                            }}
                                dateFormat="dd.MM.yyyy"
                                id="issuedDateTo" />
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <label htmlFor="type">Investment</label>
                            <select value={this.state.search.investment} className="form-control" id="investment" onChange={this.handleChange.bind(this)}>
                                <option key={-1} value={-1}>All</option>
                                {this.state.investmentSelect.map(investment => {
                                    return (
                                        <option value={investment.id} key={investment.id}>{investment.client.name}: {investment.description}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                    {
                        this.state.loading == true
                            ?
                            <p><em>Loading data...</em></p>
                            :
                            this.state.investments.length == 0
                                ?
                                <div className="alert alert-success">All issued invoices are paid</div>
                                :
                                <div>
                                    <DataTable
                                        columns={columns}
                                        data={this.state.investments}
                                        pagination={true}
                                        paginationPerPage={25}
                                        paginationRowsPerPageOptions={[10, 25, 50, 100]}
                                        highlightOnHover
                                        defaultSortAsc={false}
                                        defaultSortField="dateCreated"
                                        noHeader={true}
                                    />
                                    <div className="col-sm-12 text-right">Open value: <b>{this.state.totalOpen.toLocaleString('de-DE', {
                                        style: 'currency',
                        currency: 'EUR',
                    })}</b></div>
                                </div>
                    }
                </div >
            </div>
        );
    }
}