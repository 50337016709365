import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService'
import { Modal } from './modals/modalPartner.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faEdit } from '@fortawesome/free-solid-svg-icons'
import { PartnerEnum } from "./Enums";
import DataTable from 'react-data-table-component';


export const emptyModalObj = { id: -1, name: "", type: 0 };
class PartnerTable extends React.Component {
  getColor(type) {
    let color = "black";
    switch (type) {
      case 0:
        color = "#5cb85c";
        break;
      case 1:
        color = "#d9534f";
        break;
      case 2:
        color = "#5bc0de";
        break;
      case 3:
        color = "black"
        break;
    }
    return color;
  }

  render() {
    const columns = [
      {
        name: 'Name',
        selector: 'name',
        sortable: true,
        wrap: true
      },
      {
        name: 'Type',
        selector: 'type',
        sortable: true,
        format: (row, i) => {
          return <span key={i} style={{ 'color': this.getColor(row.type) }}>{PartnerEnum[row.type]}</span>
        }
        ,
        wrap: true
      },

      {
        cell: (partner) =>
          <button className="btn btn-primary btn-sm" type="button" onClick={() => this.props.modal(partner.id)}><FontAwesomeIcon icon={faEdit} />  Edit</button>
        ,
        ignoreRowClick: true,
        allowOverflow: true,
        button: false,
        right: true
      }];
    return (
      <DataTable
        columns={columns}
        data={this.props.data}
        pagination={true}
        paginationPerPage={25}
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
        highlightOnHover
        defaultSortAsc={false}
        defaultSortField="type"
        noHeader={true}
      />
    );
  }
}

export class Partners extends Component {
  constructor(props) {
    super(props);
    this.state = { partners: [], loading: true, showModal: false, modal: emptyModalObj };
  }

  componentDidMount() {
    this.populatePartnersData();
  }

  showModal = (parnerId) => {
    let modalData = this.state.partners.find(x => x.id == parnerId);
    this.setState({ showModal: true, modal: modalData == null ? emptyModalObj : modalData });
  };

  hideModal = () => {
    this.setState({ showModal: false });
  };

  savePartner = (data) => {
    if (data.name != "")
      this.updatePartnersData(data);
  }

  render() {
    let contents = this.state.loading
      ? <p><em>Loading data...</em></p>
      : <PartnerTable data={this.state.partners} modal={this.showModal} />;
    return (
      <div>
        <Modal handleClose={this.hideModal} modal={this.state.modal} show={this.state.showModal} savePartner={this.savePartner} />
        <h1 id="tabelLabel" >Partners</h1>
        <button className="btn btn-primary mt-2 mb-5" onClick={this.showModal}><FontAwesomeIcon icon={faPlus} /> Add new partner</button>
        <br />
        {contents}
      </div>
    );
  }

  async populatePartnersData() {
    const token = await authService.getAccessToken();
    const response = await fetch('partners', {
      headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    });
    const data = await response.json();
    this.setState({ partners: data, loading: false });
  }

  async updatePartnersData(req) {
    const token = await authService.getAccessToken();
    const response = await fetch('partners/updatePartnersData', {
      method: 'POST',
      body: JSON.stringify(req),
      headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
    });
    const data = await response.json();
    this.setState({ partners: data, showModal: false });
  }
}
