export const PartnerEnum = Object.freeze({ 0: "Investor", 1: "Partner", 2: "Manger", 3: "Client" })
export const emptyInvestObj = {
    id: -1,
    clientId: -1,
    investorId: -1,
    completed: false,
    description: "",
    dateInvested: Date.now,
    insurance: "",
    interestRate: "",
    moneyInvested: "",
    loanMaturity: ""
}

export const emptyModalObj = { id: -1, name: "", type: 0 };