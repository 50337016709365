import './modal.css';
import React from 'react';

export const Modal = ({ handleClose, show, title, content, handleConfirm, confirmText }) => {
    const showHideClassName = show ? "modal display-block" : "modal display-none";
    return (
        <div className={showHideClassName}>
            <section className="modal-main modal-md modal-border" >
                <div className="modal-header">
                    <h5>{title}</h5>
                </div>
                <div className="modal-body">
                    {content}
                </div>
                <div className="modal-footer">
                    <button className="btn btn-sm btn-default" type="button" onClick={handleClose}>
                        Close
                      </button>
                    <button className="btn btn-sm btn-primary" type="button" onClick={handleConfirm}>
                        {confirmText}
                    </button>
                </div>
            </section>
        </div>
    );
};