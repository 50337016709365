import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import './../investment.css';
import "react-datepicker/dist/react-datepicker.css";
import authService from './../api-authorization/AuthorizeService';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Modal } from "./../modals/modalAddInvoice";
export class InvoiceToMake extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            investments: [],
            modal: {},
            modalVisible: false,
            managers: [],
            invType: 0
        };
    }

    componentDidMount() {
        this.populateInvestmentProcData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (JSON.stringify(this.state) !== JSON.stringify(prevState) || this.props.forceRefresh == true)
            this.populateInvestmentProcData();
    }

    issueInvoice(id) {
        let inv = this.state.investments.find(x => x.investment.id == id).investment;
        this.setState({ modal: inv, modalVisible: true });
    }

    hideModal = () => {
        this.setState({ modalVisible: false });
    };

    async populateInvestmentProcData(id) {
        let res = [];
        if (id != -1) {
            const token = await authService.getAccessToken();
            const response = await fetch('invoice/getnotpaidiinvestments', {
                method: 'POST',
                body: JSON.stringify(""),
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
            });
            res = await response.json();
        }
        this.setState({ investments: res.investments, loading: false, managers: res.managers });
    }

    issueAnInvoice = async (state) => {
        let req = state;
        const token = await authService.getAccessToken();
        const response = await fetch('invoice/issueaninvoice', {
            method: 'POST',
            body: JSON.stringify(req),
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        });
        let res = await response.json();
        this.setState({ modalVisible: false });
        this.props.refreshTable();
    }

    renderDate(date){
        let d = new Date(date);
        return d.getDate() + "." + (parseInt(d.getMonth()) + 1) + "." + d.getFullYear();
    }

    render() {
        return (
            <div>
                <h1 id="tabelLabel" >Issue Invoices</h1>
                <Modal new={false} modal={this.state.modal} issueAnInvoice={this.issueAnInvoice} handleClose={this.hideModal} show={this.state.modalVisible} managers={this.state.managers} />
                {this.state.loading == true
                    ?
                    <p><em>Loading data...</em></p>
                    :
                    this.state.investments.length == 0
                        ?
                        <div className="alert alert-success">
                            To date, all invoices have been issued</div>
                        :
                        <div>
                            <table className='table table-striped' aria-labelledby="tabelLabel">
                                <thead>
                                    <tr>
                                        <th>Client</th>
                                        <th>Investment</th>
                                        <th>Date Invested</th>
                                        <th>Day of Invoice Issue</th>
                                        <th>Progress</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.investments.map(inv =>
                                        <tr key={inv.investment.id}>
                                            <td>{inv.investment.client.name}</td>
                                            <td>{inv.investment.description}</td>
                                            <td>{
                                                this.renderDate(inv.investment.dateInvested)
                                                }</td>
                                            <td>{inv.investment.dayOfInvoice}</td>
                                            <td>
                                                <OverlayTrigger
                                                    placement={"top"}
                                                    overlay={
                                                        <Tooltip id={`tooltip-top`}>
                                                            {inv.issuedPaidInvoices + "/" + (inv.issuedInvoices-inv.issuedPaidInvoices) + "/" + inv.investment.monthsPayment}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <div className="progress" >
                                                        <div className="progress-bar bg-success" role="progressbar" style={{ "width": (inv.issuedPaidInvoices / inv.investment.monthsPayment) * 100 + "%" }} aria-valuenow={inv.issuedInvoices} aria-valuemin="0" aria-valuemax={inv.investment.monthsPayment}></div>
                                                        <div className="progress-bar" role="progressbar" style={{ "width": ((inv.issuedInvoices-inv.issuedPaidInvoices) / inv.investment.monthsPayment) * 100 + "%" }} aria-valuenow={inv.issuedInvoices} aria-valuemin="0" aria-valuemax={inv.investment.monthsPayment}></div>
                                                    </div>
                                                </OverlayTrigger>
                                            </td>
                                            <td style={{ "textAlign": "right" }}><button className="btn btn-primary btn-sm" type="button" onClick={() => this.issueInvoice(inv.investment.id)}><FontAwesomeIcon icon={faPlus} />  Issue Invoice</button></td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                }

            </div>
        );
    }
}