import React, { Component } from 'react';
import { Modal } from './../modals/modalInvestmentPartner.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faEdit } from '@fortawesome/free-solid-svg-icons'
import './../investment.css';
import "react-datepicker/dist/react-datepicker.css";
import authService from './../api-authorization/AuthorizeService'
import { emptyState } from "./../modals/modalInvestmentPartner";
import { PartnerEnum } from "./../Enums";

export class InvestmentPartnerContainer extends React.Component {
    constructor(props) {
        super(props);
        let moSt = emptyState;
        moSt.investmentId = this.props.investmentId;
        this.state = {
            showModal: false,
            ModalData: moSt,
            partners: []
        };
    }

    hideModal = () => {
        this.setState({ showModal: false });
    };

    showModal = (id) => {
        let moSt = this.state.ModalData;
        moSt.investmentId = this.props.investmentId;

        moSt.id = -1;
        moSt.partnerId = -1;
        moSt.per = 1;

        if (id != -1) {
            const partner = this.state.partners.find(x => x.id == id);
            moSt.per = partner.percentage;
            moSt.partnerId = partner.partner.id;
            moSt.id = id;
        }

        this.setState({ showModal: true, ModalData: moSt });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.investmentId != this.props.investmentId)
            this.populateInvestmentProcData(this.props.investmentId);
    }

    componentDidMount() {
        if (this.props.investmentId != null)
            this.populateInvestmentProcData(this.props.investmentId);
    }

    updateTable = (id) => {
        this.populateInvestmentProcData(id);
    }

    async populateInvestmentProcData(id) {
        let res = [];
        if (id != -1) {
            const token = await authService.getAccessToken();
            const response = await fetch('investment/getinvestmentpartners', {
                method: 'POST',
                body: JSON.stringify(id),
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
            });
            res = await response.json();
        }
        this.setState({ partners: res.partnerOwnership });
    }

    render() {
        return (
            <div className="col-sm-12 mt-3">
                <h3>Partners List</h3>
                <Modal updateTable={this.updateTable} handleClose={this.hideModal} show={this.state.showModal} modal={this.state.ModalData} allPartners={this.props.allPartners} />
                <table className='table table-striped' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th>Partner</th>
                            <th>Type</th>
                            <th>%</th>
                            <th hidden={this.props.disabled} style={{ "textAlign": "right" }}><button onClick={() => { this.showModal(-1) }} className="btn btn-success btn-sm" type="button" ><FontAwesomeIcon icon={faPlus} /></button></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.partners.map(p =>
                                <tr key={p.id}>
                                    <td>{p.partner.name}</td>
                                    <td>{PartnerEnum[p.partner.type]}</td>
                                    <td>{p.percentage}</td>
                                    <td hidden={this.props.disabled} style={{ "textAlign": "right" }}><button onClick={() => { this.showModal(p.id) }} className="btn btn-warning btn-sm" type="button" ><FontAwesomeIcon icon={faEdit} /></button></td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
        );
    }
}