import './modal.css';
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { PartnerEnum } from './../Enums';
import authService from './../api-authorization/AuthorizeService'

export const emptyState = {
  id: -1,
  partnerId: -1,
  investmentId: -1,
  per: 1,
};

export class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = { modal: emptyState };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.modal != this.props.modal)
      this.setState({ modal: this.props.modal });
  }

  handleChange(e) {
    let m = this.state.modal;
    m[e.target.id] = e.target.value;
    this.setState({
      modal: m
    });
  }

  async removeEntry() {
    const token = await authService.getAccessToken();
    const response = await fetch('investment/deletepartnerownership', {
      method: 'POST',
      body: JSON.stringify(this.state.modal.id),
      headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
    });
    const res = await response.json();
    this.props.updateTable(this.state.modal.investmentId);
    this.props.handleClose();
  }

  async savePartner() {
    const data = {
      InvestmentId: this.state.modal.investmentId,
      Partnerid: parseInt(this.state.modal.partnerId),
      Per: parseFloat(this.state.modal.per),
      Id: this.state.modal.id
    }
    const token = await authService.getAccessToken();
    const response = await fetch('investment/upsertinvestmentpartner', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
    });
    const res = await response.json();
    this.props.updateTable(this.state.modal.investmentId);

    if (this.state.modal.id != -1)
      this.props.handleClose();
  }

  render() {
    let showHideClassName = this.props.show ? "modal display-block" : "modal display-none";
    return (
      <div className={showHideClassName}>
        <section className="modal-main modal-md modal-border" >
          <div className="modal-header">
            <h5>{this.state.modal.id == -1 ? "Add new partner" : "Edit partner"}</h5>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label htmlFor="name">Partner</label>
              <select className="form-control" id="partnerId" value={parseInt(this.state.modal.partnerId)} onChange={this.handleChange.bind(this)}>
                <option value="-1"></option>
                {this.props.allPartners.map(inv =>
                  <option key={inv.id} value={inv.id}>{inv.name} ({PartnerEnum[inv.type]})</option>
                )}
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="name">%</label>
              <input type="number" className="form-control" id="per" value={this.state.modal.per} onChange={this.handleChange.bind(this)} />
            </div>
          </div>

          <div className="modal-footer">
            <button disabled={this.state.modal.id == -1} style={{ "float": "left" }} className="btn btn-sm btn-danger" type="button" onClick={() => this.removeEntry()}>
              <FontAwesomeIcon icon={faTrash} /> Remove
        </button>
            <button className="btn btn-sm btn-default" type="button" onClick={() => this.props.handleClose()}>
              <FontAwesomeIcon icon={faTimes} /> Close
        </button>
            <button className="btn btn-sm btn-success" type="button" onClick={() => this.savePartner()}>
              <FontAwesomeIcon icon={faEdit} /> Save
        </button>
          </div>
        </section>
      </div>
    );
  }
}