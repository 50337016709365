import './modal.css';
import React, { Component } from 'react';
import { emptyModalObj } from "./../Partners";

export class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = { modal: emptyModalObj };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.modal != this.props.modal)
      this.setState({ modal: this.props.modal });
  }

  handleChange(event) {
    var m = this.state.modal;
    if (event.target.id == "name")
      m.name = event.target.value;
    else
      m.type = parseInt(event.target.value);

    this.setState({ modal: m })
  }

  render() {
    let showHideClassName = this.props.show ? "modal display-block" : "modal display-none";
    return (
      <div className={showHideClassName}>
        <section className="modal-main modal-md modal-border" >
          <div className="modal-header">
            <h5>{this.state.modal.id == null ? "Add new partner" : "Edit partner"}</h5>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input type="text" className="form-control" id="name" defaultValue={this.state.modal.name} placeholder="Enter parner name" onBlur={this.handleChange.bind(this)} />
            </div>

            <div className="form-group">
              <label htmlFor="type">Partner type</label>
              <select className="form-control" id="type" value={this.state.modal.type} onChange={this.handleChange.bind(this)}>
                <option value="0">Investor</option>
                <option value="1">Partner</option>
                <option value="2">Manger</option>
                <option value="3">Client</option>
              </select>
            </div>
          </div>

          <div className="modal-footer">
            <button className="btn btn-sm btn-default" type="button" onClick={this.props.handleClose}>
              Close
        </button>
            <button className="btn btn-sm btn-success" type="button" onClick={() => this.props.savePartner(this.state.modal)}>
              Save
        </button>
          </div>
        </section>
      </div>
    );
  }
}