import React, { Component, Fragment } from 'react';
import authService from './api-authorization/AuthorizeService';
import { InvoicesMain } from './Invoices';

export class Home extends Component {
  static displayName = Home.name;

  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      userName: null
    };
  }

  componentDidMount() {
    this._subscription = authService.subscribe(() => this.populateState());
    this.populateState();
  }

  componentWillUnmount() {
    authService.unsubscribe(this._subscription);
  }

  async populateState() {
    const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
    this.setState({
      isAuthenticated,
      userName: user && user.name
    });
  }

  render() {
    const { isAuthenticated } = this.state;
    if (isAuthenticated) {
      return (<div>
        <InvoicesMain />
      </div>);
    }
    return (
      <div>
        <h1>Hello!</h1>
        <p>Please login in to continue.</p>
      </div>
    );
  }
}
