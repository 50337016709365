import React, { Component } from 'react';
import { InvoiceToMake } from './invoices/invoicesToMake';
import { InvoicesNotPaid } from './invoices/invoicesNotPaid';

export class InvoicesMain extends Component {
    constructor(props) {
        super(props);
        this.state = { forceRefresh: false, forceRefresh2: false };
    }

    refreshTable = () => {
        this.setState({
            forceRefresh: true
        }, () => {
            this.setState({
                forceRefresh: false
            });
        });
    }

    refreshTable2 = () => {
        this.setState({
            forceRefresh2: true
        }, () => {
            this.setState({
                forceRefresh2: false
            });
        });
    }

    render() {
        return (
            <div>
                <InvoiceToMake refreshTable={this.refreshTable} forceRefresh={this.state.forceRefresh2} />
                <InvoicesNotPaid refreshTable={this.refreshTable2} forceRefresh={this.state.forceRefresh} />
            </div>
        );
    }
}
