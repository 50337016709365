import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faCheck, faBan, faEdit } from '@fortawesome/free-solid-svg-icons'
import authService from './api-authorization/AuthorizeService';
import { Modal } from "./modals/modalAddInvoice";
import { ModalEdit } from "./modals/modalEditInvoice";
import DataTable from 'react-data-table-component';

export const ExpandedComponent = ({ data, edit }) => {
    return <table className="table table_faktura">
        <thead>
            <tr>
                <th>Invoice Nr</th>
                <th>Issuer</th>
                <th>Date Issued</th>
                <th>Due Date</th>
                <th>Invoice Value</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            {
                data.fakture.map(i => {
                    return <tr key={i.invoiceNr}>
                        <td>{i.invoiceNr}</td>
                        <td>{i.companyIssuer.name}</td>
                        <td>{getFormatDate(i.invoiceCreatedDate)}</td>
                        <td>{getFormatDate(i.dueDate)}</td>
                        <td>{i.invoiceValue.toLocaleString('de-DE', {
                            style: 'currency',
                            currency: 'EUR',
                        })}</td>
                        <td>
                            {i.paid == true ? <span style={{ "color": "#28a745", "marginRight": "2px" }}>{getFormatDate(i.paidDate)}</span> : null}
                            {i.paid == true ? <FontAwesomeIcon icon={faCheck} color={"#28a745"} /> : <FontAwesomeIcon icon={faBan} color={"#dc3545"} />}
                            {edit ? <button style={{ "marginLeft": "2px", "marginRight": "8px" }} className="btn btn-primary btn-sm ml-2" type="button" onClick={() => this.editInvoice(i.id)}><FontAwesomeIcon icon={faEdit} /></button> : null}
                        </td>
                    </tr>
                })
            }
        </tbody>
    </table >
};

export function getFormatDate(date) {
    let d = new Date(date);
    return d.getDate() + "." + (parseInt(d.getMonth()) + 1) + "." + d.getFullYear()
}
export class InvoicesList extends Component {
    constructor(props) {
        super(props);
        var d = new Date();
        this.state = {
            invoices: [],
            investments: [],
            loading: true,
            search: {
                issuedDateFrom: new Date(d.getFullYear() - 1, d.getMonth(), d.getDate(), 0, 0, 0),
                issuedDateTo: new Date(d.getFullYear(), d.getMonth(), d.getDate(), 23, 59, 59),
                type: -1,
                investment: -1,
                paid: -1
            },
            modal: {},
            editInvoiceId: -1,
            modalVisible: false,
            modalEditVisible: false,
            managers: []
        };
    }

    componentDidMount() {
        this.populateInvestmentProcData();
        this.populateSearchData();
    }

    hideModal = () => {
        this.setState({ modalVisible: false, modalEditVisible: false });
    };

    handleAddInvoice() {
        this.setState({ fatkura_investment_id: null, fatkura_invoice_id: null, faktura: null, modalVisible: true });
    }

    populateSearchData = async () => {
        let req = this.state.search;
        this.setState({ loading: true });
        req.type = -1;
        req.investment = parseInt(req.investment);
        req.paid = parseInt(req.paid);
        const token = await authService.getAccessToken();
        const response = await fetch('invoice/getinvoices', {
            method: 'POST',
            body: JSON.stringify(this.state.search),
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        });
        let res = await response.json();
        this.setState({ invoices: res.invoices, loading: false });
    }

    async populateInvestmentProcData() {
        this.setState({ loading: true });
        let res = [];
        const token = await authService.getAccessToken();
        const response = await fetch('invoice/getinvoicesinit', {
            method: 'POST',
            body: JSON.stringify(""),
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        });
        res = await response.json();
        this.setState({ managers: res.managers, investments: res.investments });
    }

    issueAnInvoice = async (state) => {
        let req = state;
        req.invType = parseInt(req.type);
        req.manager = parseInt(req.manager);
        const token = await authService.getAccessToken();
        const response = await fetch('invoice/issueaninvoice', {
            method: 'POST',
            body: JSON.stringify(req),
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        });
        let res = await response.json();
        this.setState({ modalVisible: false });
        this.populateSearchData();
    }

    handleChange(e) {
        let m = this.state.search;
        m[e.target.id] = e.target.value;
        this.setState({
            search: m
        });
        this.populateSearchData();
    }

    handleChangeDate1(handleChangeDate) {
        let m = this.state.search;
        m["issuedDateFrom"] = new Date(handleChangeDate.getFullYear(), handleChangeDate.getMonth(), handleChangeDate.getDate(), 0, 0, 0);
        this.setState({
            search: m
        });
        this.populateSearchData();
    }

    handleChangeDate2(handleChangeDate) {
        let m = this.state.search;
        m["issuedDateTo"] = new Date(handleChangeDate.getFullYear(), handleChangeDate.getMonth(), handleChangeDate.getDate(), 23, 59, 59);
        this.setState({
            search: m
        });
        this.populateSearchData();
    }

    issueFaktura(invoiceId, investmentId) {
        this.setState({ fatkura_investment_id: investmentId, fatkura_invoice_id: invoiceId, faktura: true, modalVisible: true });
    }

    editInvoice(invoiceId) {
        this.setState({ modalEditVisible: true, editInvoiceId: invoiceId });
    }

    render() {
        const conditionalRowStyles = [
            {
                when: row => row.invoiceType == 1,
                style: {
                    backgroundColor: 'rgba(220, 220, 220, 0.8)',
                    color: 'black',
                }
            }, {
                when: row => row.invoiceType == 0,
                style: {
                    borderTop: '2px solid black',
                    color: 'black',
                }
            }
        ];


        const columns = [
            {
                name: 'Investment',
                selector: (row, i) => {
                    return row.investment.client.name + ": " + row.investment.description
                },
                wrap: true,
                sortable: true
            },
            {
                name: 'Invoice Nr',
                selector: 'invoiceNr',
                wrap: true,
                sortable: true
            },
            {
                name: 'Issuer',
                selector: 'companyIssuer.name',
                wrap: true,
                sortable: true
            },
            {
                name: 'Date Issued',
                selector: 'invoiceCreatedDate',
                format: (row, i) => {
                    return getFormatDate(row.invoiceCreatedDate);
                },
                wrap: true,
                width: "100px",
                sortable: true
            },
            {
                name: 'Due Date',
                selector: 'dueDate',
                format: (row, i) => {
                    return getFormatDate(row.dueDate);
                },
                wrap: true,
                width: "100px",
                sortable: true
            },
            {
                name: 'Invoice Value',
                selector: 'invoiceValue',
                format: (row, i) => {
                    return row.invoiceValue.toLocaleString('de-DE', {
                        style: 'currency',
                        currency: 'EUR',
                    });
                },
                wrap: true,
                sortable: true
            },
            {
                name: 'Type',
                selector: 'invoiceType',
                sortable: false,
                format: (row, i) => {
                    return row.invoiceType == 0 ? "Invoice" : "Faktura"
                }, wrap: true,
                width: "100px"
            },
            {
                name: "",
                cell: (inv) => {
                    const issueF = inv.invoiceType == 0 ? <button style={{ "marginLeft": "2px" }} className="btn btn-success btn-sm mr-2" type="button" onClick={() => this.issueFaktura(inv.invoiceNr, inv.investment.id)}><FontAwesomeIcon icon={faPlus} /></button> : null;
                    const paidIcon = inv.paid == true ? <FontAwesomeIcon icon={faCheck} color={"#28a745"} /> : <FontAwesomeIcon icon={faBan} color={"#dc3545"} />;
                    const dd = new Date(inv.paidDate);
                    const paidD = inv.paid == true ? <span style={{ "color": "#28a745", "marginRight": "2px" }}>{dd.getDate() + "." + (dd.getMonth() + 1) + "." + dd.getFullYear()}</span> : null;
                    const editD = <button style={{ "marginLeft": "2px" }} className="btn btn-primary btn-sm mr-2" type="button" onClick={() => this.editInvoice(inv.id)}><FontAwesomeIcon icon={faEdit} /></button>;
                    return <div>{paidD}  {paidIcon}  {issueF} {editD}</div>;
                },
                ignoreRowClick: true,
                allowOverflow: true,
                button: false,
                right: true,
                width: "200px"
            }];

        return (
            <div>
                <Modal new={true} modal={this.state.modal} issueAnInvoice={this.issueAnInvoice} handleClose={this.hideModal} show={this.state.modalVisible} investments={this.state.investments} managers={this.state.managers} fatkura_investment_id={this.state.fatkura_investment_id} fatkura_invoice_id={this.state.fatkura_invoice_id} faktura={this.state.faktura} />
                <ModalEdit show={this.state.modalEditVisible} invoiceId={this.state.editInvoiceId} handleClose={this.hideModal} refreshTable={this.populateSearchData} />
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <h1 id="tabelLabel" >Invoice List</h1>
                    </div>
                    <div className="col-md-6 col-sm-12" style={{ textAlign: "right" }}>
                        <button className="btn btn-md btn-primary" type="button" onClick={() => this.handleAddInvoice()}>
                            <FontAwesomeIcon icon={faPlus} /> Issue Invoice
                        </button>
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-md-3 col-sm-12">
                        <label htmlFor="issuedDateFrom">Issued From</label>
                        <DatePicker selected={this.state.search.issuedDateFrom} onChange={date => {
                            this.handleChangeDate1(date)
                        }}
                            dateFormat="dd.MM.yyyy"
                            id="issuedDateFrom" />
                    </div>
                    <div className="col-md-3 col-sm-12">
                        <label htmlFor="issuedDateTo">Issued To</label>
                        <DatePicker selected={this.state.search.issuedDateTo} onChange={date => {
                            this.handleChangeDate2(date)
                        }}
                            dateFormat="dd.MM.yyyy"
                            id="issuedDateTo" />
                    </div>
                    <div className="col-md-3 col-sm-12">
                        <label htmlFor="type">Investment</label>
                        <select value={this.state.search.investment} className="form-control" id="investment" onChange={this.handleChange.bind(this)}>
                            <option key={-1} value={-1}>All</option>
                            {this.state.investments.map(inv =>
                                <option key={inv.id} value={inv.id}>{inv.client.name}: {inv.description}</option>

                            )}
                        </select>
                    </div>
                    <div className="col-md-2 col-sm-12">
                        <label htmlFor="type">Type</label>
                        <select value={this.state.search.type} className="form-control" id="type" onChange={this.handleChange.bind(this)}>
                            <option key={-1} value={-1}>All</option>
                        </select>
                    </div>
                    <div className="col-md-1 col-sm-12">
                        <label htmlFor="paid">Paid</label>
                        <select value={this.state.search.paid} className="form-control" id="paid" onChange={this.handleChange.bind(this)}>
                            <option key={-1} value={-1}>All</option>
                            <option key={1} value={1}>Yes</option>
                            <option key={0} value={0}>No</option>
                        </select>
                    </div>
                </div>
                {
                    this.state.loading == true
                        ?
                        <p><em>Loading data...</em></p>
                        :
                        <DataTable
                            columns={columns}
                            data={this.state.invoices}
                            pagination={true}
                            paginationPerPage={50}
                            paginationRowsPerPageOptions={[10, 25, 50, 100]}
                            highlightOnHover
                            defaultSortAsc={false}
                            defaultSortField="type"
                            noHeader={true}
                            conditionalRowStyles={conditionalRowStyles}
                            expandableRows
                            expandableRowDisabled={row => row.fakture.length == 0}
                            expandableRowsComponent={<ExpandedComponent edit={true} />}
                        />
                }
            </div >
        );
    }
}
