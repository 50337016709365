import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService'
import './investment.css';
import "react-datepicker/dist/react-datepicker.css";
import { InvestmentTable } from "./investments/investmentTable";
import { InvestmentContainer } from "./investments/investmentContainer";

export class Investment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ivestments: [],
            partners: [],
            investors: [],
            clients: [],
            showInvestCont: false,
            investmentId: -1,
            inevestTableLoading: true,
            disabled: false
        };
    }

    componentDidMount() {
        this.populateInvestmentData();
    }

    toggleInvestContainerwModal = () => {
        this.setState({ showInvestCont: !this.state.showInvestCont, investmentId: -1,disabled: false });
    };

    closeInvestContainerwModal = () => {
        this.setState({ showInvestCont: false, investmentId: -1 });
    }

    investmentContainer = (id) => {
        this.setState({ showInvestCont: true, investmentId: id, disabled: false });
    }

    investmentContainerDisabled = (id) => {
        this.setState({ showInvestCont: true, investmentId: id, disabled: true });
    }

    renderTable = () => {
        this.populateInvestmentData();
    }

    render() {
        return (
            <div>
                <h1 id="tabelLabel" >Investments</h1>
                <button className="btn btn-primary mt-2 mb-5" onClick={this.toggleInvestContainerwModal}>{this.state.showInvestCont == false ? "Add New Investment" : "Hide Panel"}</button>
                <br />
                <InvestmentContainer show={this.state.showInvestCont} investmentId={this.state.investmentId}
                    investors={this.state.investors}
                    clients={this.state.clients}
                    partners={this.state.partners}
                    closeInvestContainerwModal={this.closeInvestContainerwModal}
                    renderTable={this.renderTable}
                    allPartners={this.state.allPartners}
                    disabled={this.state.disabled}
                />
                <InvestmentTable data={this.state.ivestments} openInvestment={this.investmentContainer} showInvestment={this.investmentContainerDisabled} loading={this.state.inevestTableLoading} />
            </div>
        );
    }

    async populateInvestmentData() {
        const token = await authService.getAccessToken();
        const response = await fetch('investment', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        this.setState({
            ivestments: data.initialInvestments,
            partners: data.partners,
            investors: data.investor,
            clients: data.clients,
            inevestTableLoading: false,
            allPartners: data.partners.concat(data.investor).concat(data.clients)
        });
    }
}
