import './modal.css';
import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import authService from '../api-authorization/AuthorizeService';

export class ModalEdit extends Component {
  constructor(props) {
    super(props);
    this.state = { confirmDelete: false, invoice: { invoiceId: -1, invoiceNr: "", investment: "", issuer: "", issuedDate: "", paidDate: "", dueDate: "", invoiceValue: 0, paid: false } };
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.invoice.invoiceId != this.props.invoiceId && (this.props.invoiceId != -1 && this.state.invoideId != -1)) {
      const token = await authService.getAccessToken();
      const response = await fetch('invoice/getinvoice', {
        method: 'POST',
        body: JSON.stringify(parseInt(this.props.invoiceId)),
        headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
      });
      let res = await response.json();
      let m = this.state.invoice;
      m.invoiceValue = res.invoiceValue;
      m.investment = res.investment.client.name + ": " + res.investment.description;
      m.issuer = res.companyIssuer.name;
      m.invoiceId = this.props.invoiceId;
      m.invoiceNr = res.invoiceNr;
      m.dueDate = new Date(res.paidDate);
      m.paid = res.paid;
      m.issuedDate = new Date(res.invoiceCreatedDate);
      m.paidDate = new Date(res.paidDate);

      const minD = new Date('2021-01-01Z12:00:00:000');
      m.dueDate = m.dueDate < minD ? minD : m.dueDate;
      m.issuedDate = m.issuedDate < minD ? minD : m.issuedDate;
      m.paidDate = m.paidDate < minD ? minD : m.paidDate;


      this.setState({
        invoice: m
      });
    }
  }

  handleChange(e) {
    const id = e.target.id;
    let m = this.state.invoice;
    m[id] = e.target.value;
    this.setState({
      invoice: m
    });
  }

  handleChangeDate(id, handleChangeDate) {
    let m = this.state.invoice;
    m[id] = new Date(handleChangeDate.getFullYear(), handleChangeDate.getMonth(), handleChangeDate.getDate(), 12, 0, 0);
    this.setState({
      invoice: m
    });
  }

  async updateInvoice() {
    const req = this.state.invoice;
    const token = await authService.getAccessToken();
    const response = await fetch('invoice/updateInvoice', {
      method: 'POST',
      body: JSON.stringify({
        dueDate: req.dueDate,
        issuedDate: req.issuedDate,
        paidDate: req.paidDate,
        paid: req.paid == "true" ? true : false,
        invoiceValue: parseFloat(req.invoiceValue),
        invoiceId: req.invoiceId
      }),
      headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
    });
    await response.json();
    this.props.handleClose();
    this.props.refreshTable();
  }

  confirmDelete(showHide) {
    this.setState({
      confirmDelete: showHide
    });
  }

  async deleteInvoice() {
    const token = await authService.getAccessToken();
    const response = await fetch('invoice/markasremoved', {
      method: 'POST',
      body: JSON.stringify(this.state.invoice.invoiceId),
      headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
    });
    await response.json();
    this.props.handleClose();
    this.props.refreshTable();
  }

  render() {
    let showHideClassName = this.props.show ? "modal display-block" : "modal display-none";
    return (
      <div className={showHideClassName}>
        <section className="modal-main modal-md modal-border" >
          <div className="modal-header">
            <h5>Update an Invoice</h5>
          </div>
          <div className="modal-body">

            <div className="form-group row">
              <div className="col-md-4 cok-sm-12">
                <label htmlFor="name">Investment</label>
                <input disabled={true} className="form-control" id="invested" defaultValue={this.state.invoice.investment} />
              </div>
              <div className="col-md-4 cok-sm-12">
                <label htmlFor="name">Invoice Nr</label>
                <input disabled={true} className="form-control" id="invoiceNr" defaultValue={this.state.invoice.invoiceNr} />
              </div>
              <div className="col-md-4 cok-sm-12">
                <label htmlFor="name">Issuer</label>
                <input disabled={true} className="form-control" id="period" defaultValue={this.state.invoice.issuer} />
              </div>
            </div>

            <div className="form-group row">

              <div className="col-md-2 col-sm-12">
                <label htmlFor="paid">Paid</label>
                <select value={this.state.invoice.paid} className="form-control" id="paid" onChange={this.handleChange.bind(this)}>
                  <option key={0} value={false}>No</option>
                  <option key={1} value={true}>Yes</option>
                </select>
              </div>

              <div className="col-md-2 cok-sm-12">
                <label htmlFor="name">Paid Date</label>
                <DatePicker selected={this.state.invoice.paidDate} onChange={date => {
                  this.handleChangeDate("paidDate", date)
                }}
                  dateFormat="dd.MM.yyyy"
                  id="paidDate" />
              </div>


              <div className="col-md-2 cok-sm-12">
                <label htmlFor="name">Date Issued</label>
                <DatePicker selected={this.state.invoice.issuedDate} onChange={date => {
                  this.handleChangeDate("issuedDate", date)
                }}
                  dateFormat="dd.MM.yyyy"
                  id="issuedDate" />
              </div>

              <div className="col-md-2 cok-sm-12">
                <label htmlFor="name">Due Date</label>
                <DatePicker selected={this.state.invoice.dueDate} onChange={date => {
                  this.handleChangeDate("dueDate", date)
                }}
                  dateFormat="dd.MM.yyyy"
                  id="dueDate" />
              </div>

              <div className="col-md-4 cok-sm-12">
                <label htmlFor="name">Invoice Value</label>
                <input type="number" className="form-control" id="invoiceValue" value={this.state.invoice.invoiceValue} onChange={this.handleChange.bind(this)} />
              </div>
            </div>

            <div className="modal-footer">
              <button className="btn btn-sm btn-default" type="button" onClick={this.props.handleClose}>
                Close
        </button>
              <button className="btn btn-sm btn-danger" type="button" onClick={() => this.confirmDelete(true)}>
                <FontAwesomeIcon icon={faTrash} /> Remove Invoice
        </button>

              <button className="btn btn-sm btn-success" type="button" onClick={() => this.updateInvoice()}>
                <FontAwesomeIcon icon={faPlus} /> Update Invoice
        </button>
              {this.state.confirmDelete &&
                <div className="col-sm-12 p-0 text-right" >
                  <button className="btn btn-sm btn-default" type="button" onClick={() => this.confirmDelete(false)}>
                    No
        </button>

                  <button className="btn btn-sm btn-danger" type="button" onClick={() => this.deleteInvoice()}>
                    <FontAwesomeIcon icon={faTrash} /> Yes
        </button>
                </div>
              }
            </div>
          </div>
        </section>
      </div >
    );
  }
}