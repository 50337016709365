import './modal.css';
import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import authService from './../api-authorization/AuthorizeService';

export class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = { investor: {}, partners: [], invPartner: 0, modal: {}, invoice: { type: 0, invoiceNr: "", issuedDate: new Date(), dueDate:new Date(Date.now() + 15 * 24*60*60*1000), invoiceValue: 0, paid: false, investment: -1, manager: -1, primaryInvoiceId: "" } };
  }

  componentDidUpdate(prevProps, prevState) {
    const fakturaValid = this.props.faktura && this.state.invoice.type != this.props.faktura;
    const fakturaInvValid = this.props.fatkura_investment_id && this.state.invoice.investment != this.props.fatkura_investment_id;
    const fakturaInvoiceValid = this.props.fatkura_invoice_id && this.state.invoice.primaryInvoiceId != this.props.fatkura_invoice_id;
    let invoice = this.state.invoice;
    if (prevState.modal != this.props.modal && this.props.new == false) {
      const monthlyReturn = this.state.modal.moneyInvested != null ? (this.state.modal.moneyInvested + (this.state.modal.moneyInvested * (this.state.modal.interestRate / 100))) / this.state.modal.monthsPayment : 0;
      invoice.invoiceValue = monthlyReturn;
      invoice.manager = this.props.modal.investor != null ? this.props.modal.investor.id : -1;
      invoice.type = 0;
      this.setState({ modal: this.props.modal, invoice: invoice });
    } else if (this.props.new && (fakturaValid || fakturaInvValid || fakturaInvoiceValid)) {
      invoice.type = this.props.faktura ? 1 : 0;
      invoice.primaryInvoiceId = this.props.fatkura_invoice_id;
      invoice.investment = this.props.fatkura_investment_id;
      this.setState({ invoice: invoice }, () => { this.handleInvestmentChange(this.props.fatkura_investment_id) });
    }
  }

  handleChange(e) {
    const id = e.target.id;
    let m = this.state.invoice;
    m[id] = e.target.value;
    this.setState({
      invoice: m
    });

    if (id == "investment")
      this.handleInvestmentChange(e.target.value)
    else if (id == "manager" && this.state.invoice.type == 1)
      this.handlePartnerChange(e.target.value);
    else if (id == "type")
      this.handleInvestmentChange(this.state.invoice.investment);
  }

  handlePartnerChange = (id) => {
    let invPartner = this.state.partners.find(x => x.id == id).per;
    this.setState({
      invPartner: invPartner
    });
  }

  handleInvestmentChange = async (id) => {
    let res = [];
    let invPartner = "";
    let partners = [];
    let modal = { moneyInvested: null, monthsPayment: null, interestRate: null };
    let invoice = this.state.invoice;
    invoice.invoiceValue = 0;

    if (id != -1) {
      const inv = this.props.investments.find(x => x.id == id);

      if (parseInt(invoice.type) == 1) {
        const token = await authService.getAccessToken();
        const response = await fetch('investment/getinvestmentpartners', {
          method: 'POST',
          body: JSON.stringify(parseInt(id)),
          headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        });
        res = await response.json();
        res.partnerOwnership.forEach(element => {
          partners.push({ id: element.partner.id, name: element.partner.name, per: element.percentage });
        });
        if (partners.length > 0) {
          invoice.manager = partners[0].id;
          invPartner = partners[0].per;
        } else
          invoice.manager = -1;
      } else {
        partners = [inv.investor];
        invoice.manager = inv.investor.id;
      }
      modal = { moneyInvested: inv.moneyInvested, monthsPayment: inv.monthsPayment, interestRate: inv.interestRate };
      invoice.invoiceValue = Math.round((modal.moneyInvested + (modal.moneyInvested * (modal.interestRate / 100)) / modal.monthsPayment), 2);
    }

    invoice.primaryInvoiceId = parseInt(invoice.type) == 0 ? "" : invoice.primaryInvoiceId;

    this.setState({
      investor: id != -1 ? this.props.investments.find(x => x.id == id).investor : [], modal: modal, partners: partners, invoice: invoice, invPartner: invPartner
    });

  }

  handleChangeDate(id, handleChangeDate) {
    let m = this.state.invoice;
    m[id] = new Date(handleChangeDate.getFullYear(), handleChangeDate.getMonth(), handleChangeDate.getDate(), 12, 0, 0);
    this.setState({
      invoice: m
    });

  }

  handleAddInvoice() {
    let req = this.state.invoice;
    if (this.props.new == false) {
      req.investment = this.state.modal.id;
    }
    req.invoiceValue = parseFloat(req.invoiceValue);
    req.paid = req.paid == "false" || req.paid == false ? false : true;
    req.investment = parseInt(req.investment);
    this.props.issueAnInvoice(req);
  }

  isBtnDisabled() {
    let valid = false;
    if (this.props.new == true)
      valid = this.state.invoice.invoiceNr != "" && this.state.invoice.invoiceValue != 0 && this.state.invoice.investment != -1 && this.state.invoice.manager != -1 && (this.state.invoice.primaryInvoiceId != "" && this.state.invoice.type == 1 || this.state.invoice.type == 0)
    else
      valid = this.state.invoice.invoiceNr != "" && this.state.invoice.invoiceValue != 0;
    return !valid;
  }

  render() {
    let showHideClassName = this.props.show ? "modal display-block" : "modal display-none";
    return (
      <div className={showHideClassName}>
        <section className="modal-main modal-md modal-border" >
          <div className="modal-header">
            <h5>Issue an Invoice</h5>
          </div>
          <div className="modal-body">

            {this.props.new != null && this.props.new == true ?
              <div className="form-group row" style={{ borderBottom: "1px solid #eaeaea", paddingBottom: "1rem" }}>
                <div className="col-md-4 col-sm-12">
                  <label htmlFor="name">Investment</label>
                  <select disabled={this.props.faktura == true} value={this.state.invoice.investment} className="form-control" id="investment" onChange={this.handleChange.bind(this)}>
                    <option key={-1} value={-1}></option>
                    {this.props.investments.map(inv =>
                      <option key={inv.id} value={inv.id}>{inv.client.name}: {inv.description}</option>
                    )}
                  </select>
                </div>
                <div className="col-md-4 col-sm-12">
                  <label htmlFor="name">Type</label>
                  <select disabled={this.props.faktura == true} value={this.state.invoice.type} className="form-control" id="type" onChange={this.handleChange.bind(this)}>
                    <option key={0} value={0}>Invoice</option>
                    <option key={1} value={1}>Faktura</option>
                  </select>
                </div>

                {this.state.invoice.type == 1 ?
                  <div className="col-md-4 col-sm-12">
                    <label htmlFor="primaryInvoiceId">Invoice Nr</label>
                    <input disabled={this.props.faktura == true} type="text" className="form-control" id="primaryInvoiceId" onChange={this.handleChange.bind(this)} value={this.state.invoice.primaryInvoiceId} />
                  </div>
                  : null}

              </div>
              : null
            }

            <div className="form-group row">
              <div className="col-md-4 cok-sm-12">
                <label htmlFor="name">Invested</label>
                <input type="number" disabled={true} className="form-control" id="invested" defaultValue={this.state.modal.moneyInvested} />
              </div>
              <div className="col-md-4 cok-sm-12">
                <label htmlFor="name">Period</label>
                <input type="number" disabled={true} className="form-control" id="period" defaultValue={this.state.modal.monthsPayment} />
              </div>
              <div className="col-md-4 cok-sm-12">
                <label htmlFor="name">Interest Rate</label>
                <input type="number" disabled={true} className="form-control" id="period" defaultValue={this.state.modal.interestRate} />
              </div>
            </div>

            <div className="form-group row">
              <div className="col-md-4 cok-sm-12">
                <label htmlFor="name">{this.state.invoice.type == 1 && this.props.new == true ? "Faktura Nr " : "Invoice Nr"}</label>
                <input type="text" className="form-control" id="invoiceNr" value={this.state.invoice.invoiceNr} onChange={this.handleChange.bind(this)} />
              </div>
              <div className="col-md-2 cok-sm-12">
                <label htmlFor="name">Date Issued</label>
                <DatePicker selected={this.state.invoice.issuedDate} onChange={date => {
                  this.handleChangeDate("issuedDate", date)
                }}
                  dateFormat="dd.MM.yyyy"
                  id="issuedDate" />
              </div>

              <div className="col-md-2 cok-sm-12">
                <label htmlFor="name">Due Date</label>
                <DatePicker selected={this.state.invoice.dueDate} onChange={date => {
                  this.handleChangeDate("dueDate",date)
                }}
                  dateFormat="dd.MM.yyyy"
                  id="dueDate" />
              </div>


              <div className="col-md-4 cok-sm-12">
                <label htmlFor="name">Invoice Value</label>
                <input type="number" className="form-control" id="invoiceValue" value={this.state.invoice.invoiceValue} onChange={this.handleChange.bind(this)} />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-md-4 col-sm-12">
                <label htmlFor="name">Issuer</label>
                <select value={this.state.invoice.manager} className="form-control" id="manager" onChange={this.handleChange.bind(this)}>
                  {this.state.invoice.type == 1 ?
                    this.state.partners.map(inv =>
                      <option key={inv.id} value={inv.id} data-per={inv.per}>{inv.name}</option>
                    ) :
                    this.props.modal.investor != null ?
                      <option key={this.props.modal.investor.id} value={this.props.modal.investor.id}>{this.props.modal.investor.name}</option>
                      : <option key={this.state.investor.id} value={this.state.investor.id}>{this.state.investor.name}</option>
                  }
                </select>
              </div>

              {this.props.new != null && this.props.new == true ?
                <div className="col-md-4 col-sm-12">
                  <label htmlFor="name">Paid</label>
                  <select value={this.state.invoice.paid} className="form-control" id="paid" onChange={this.handleChange.bind(this)}>
                    <option key={0} value={false}>No</option>
                    <option key={1} value={true}>Yes</option>
                  </select>
                </div>
                : null}

              {this.props.new != null && this.props.new == true && this.state.invoice.type == 1 ?
                <div className="col-md-4 col-sm-12">
                  <label htmlFor="name">Partner %</label>
                  <input type="number" disabled={true} className="form-control" value={this.state.invPartner} />
                </div>
                : null}

            </div>
            <div className="modal-footer">
              <button className="btn btn-sm btn-default" type="button" onClick={this.props.handleClose}>
                Close
        </button>
              <button disabled={this.isBtnDisabled()} className="btn btn-sm btn-success" type="button" onClick={() => this.handleAddInvoice()}>
                <FontAwesomeIcon icon={faPlus} /> Issue Invoice
        </button>
            </div>
          </div>
        </section>
      </div >
    );
  }
}