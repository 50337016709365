import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faEdit } from '@fortawesome/free-solid-svg-icons'
import './../investment.css';
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import DataTable from 'react-data-table-component';
import "react-datepicker/dist/react-datepicker.css";

export class InvestmentTable extends React.Component {
    render() {
        const columns = [
            {
                name: 'Description',
                selector: 'investentDescription',
                sortable: true,
                wrap: true
            },
            {
                name: 'Client',
                selector: 'client',
                sortable: true,
                wrap: true
            },
            {
                name: 'Date Invested',
                selector: "dateCreated",
                sortable: true,
                format: (row, i) => {
                    let d = new Date(row.dateCreated);
                    return d.getDate() + "." + (parseInt(d.getMonth()) + 1) + "." + d.getFullYear();
                },
                wrap: true
            },
            {
                name: "Completed",
                cell: (invs) =>
                    <div style={{ "width": "200px" }}>
                        <OverlayTrigger
                            placement={"top"}
                            overlay={
                                <Tooltip id={`tooltip-top`}>
                                    {invs.finished ? (invs.nrOfPayment + "/0/" + invs.nrOfPayment) : (invs.nrOfPaymentPaid + "/" + invs.nrOfPaymentIssued + "/" + invs.nrOfPayment)}
                                </Tooltip>
                            }
                        >
                            <div className="progress" >
                                <div className="progress-bar bg-success" role="progressbar" style={{ "width": invs.finished ? 100 + "%" : (invs.nrOfPaymentPaid / invs.nrOfPayment) * 100 + "%" }} aria-valuenow={invs.finished ? invs.nrOfPayment : invs.nrOfPaymentPaid} aria-valuemin="0" aria-valuemax={invs.nrOfPayment}></div>
                                <div className="progress-bar" role="progressbar" style={{ "width": invs.finished ? 0  + "%" : (invs.nrOfPaymentIssued / invs.nrOfPayment) * 100 + "%" }} aria-valuenow={invs.finished ? 0 : invs.nrOfPaymentIssued} aria-valuemin="0" aria-valuemax={invs.nrOfPayment}></div>
                            </div>
                        </OverlayTrigger>
                    </div>
                ,
                ignoreRowClick: true,
                allowOverflow: true,
                button: false,
            },
            {
                cell: (invs) =>
                    <div>
                        <button style={{ "marginRight": "20px" }} className="btn btn-secondary btn-sm" type="button" onClick={() => this.props.openInvestment(invs.id)}><FontAwesomeIcon icon={faEdit} />  Edit</button>
                        <button className="btn btn-primary btn-sm" type="button" onClick={() => this.props.showInvestment(invs.id)}><FontAwesomeIcon icon={faSearch} />  Show</button>
                    </div>
                ,
                ignoreRowClick: true,
                allowOverflow: true,
                button: false,
                right: true
            }];

        const data = this.props.data;
        console.log(data);
        return (
            this.props.loading ? <p><em>Loading data...</em></p> :
                <DataTable
                    columns={columns}
                    data={this.props.data}
                    pagination={true}
                    paginationPerPage={25}
                    paginationRowsPerPageOptions={[10, 25, 50, 100]}
                    highlightOnHover
                    defaultSortAsc={false}
                    defaultSortField="dateCreated"
                    noHeader={true}
                />
        );
    }
}