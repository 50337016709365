import React, { Component } from 'react';
import authService from './../api-authorization/AuthorizeService'
import './../investment.css';
import DatePicker from "react-datepicker";
import { Modal } from './../modals/modalNotify.js';
import "react-datepicker/dist/react-datepicker.css";
import { InvestmentPartnerContainer } from "./investPartners";
import { InvoicesDataTable } from '../invoices/invoicesDataTable';

let investments = {
    id: -1,
    clientId: -1,
    investorId: -1,
    completed: false,
    description: "",
    dateInvested: new Date(),
    insurance: "",
    interestRate: "",
    moneyInvested: "",
    dayOfInvoice: 1,
    monthsPayment: 1,
    finished: false

};

export class InvestmentContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = { invoicesToShow: [], investmentId: null, investments: investments, updateLoading: false, showModal: false, ModalContent: "", ModalTitle: "" };
    }

    handleInputChange(e) {
        let m = this.state.investments;
        m[e.target.id] = e.target.value;
        this.setState({
            investments: m
        });
    }

    handleChangeDate(handleChangeDate) {
        let m = this.state.investments;
        m["dateInvested"] = new Date(handleChangeDate.getFullYear(), handleChangeDate.getMonth(), handleChangeDate.getDate(), 0, 0, 0);
        this.setState({
            investments: m
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.investmentId != this.props.investmentId && this.props.show == true) {
            this.populateInvestmentData(this.props.investmentId);
        }
    }

    async saveInvestment(e) {
        this.setState({ updateLoading: true });
        e.preventDefault();
        const req = {
            ID: this.state.investmentId,
            MoneyInvested: parseFloat(this.state.investments.moneyInvested),
            InterestRate: parseFloat(this.state.investments.interestRate),
            Insurance: this.state.investments.insurance,
            Description: this.state.investments.description,
            DateInvested: this.state.investments.dateInvested,
            MonthsPayment: parseInt(this.state.investments.monthsPayment),
            DayOfInvoice: parseInt(this.state.investments.dayOfInvoice),
            ClientId: parseInt(this.state.investments.clientId),
            InvestorID: parseInt(this.state.investments.investorId),
            Finished: this.state.investments.finished
        }

        const token = await authService.getAccessToken();
        const response = await fetch('investment/upsertinvestment', {
            method: 'POST',
            body: JSON.stringify(req),
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        });
        const data = await response.json();
        if (data.status == 400)
            this.setState({ updateLoading: false, showModal: true, ModalTitle: "Warning", ModalContent: "Insert all fields!" });
        else {
            this.setState({ updateLoading: false, showModal: true, ModalTitle: "Success", ModalContent: "Successfully Updated!" });
            this.props.renderTable();
        }
    }

    hideModal = () => {
        this.setState({ showModal: false });
    };

    async populateInvestmentData(id) {
        let invoicesToShowTMP = [];
        let investments = {
            id: -1,
            clientId: -1,
            investorId: -1,
            completed: false,
            description: "",
            dateInvested: new Date(),
            insurance: "",
            interestRate: "",
            moneyInvested: "",
            dayOfInvoice: 1,
            monthsPayment: 1,
            finished:false
        }
        if (id != -1) {
            const token = await authService.getAccessToken();
            let response = await fetch('investment/getinvestment', {
                method: 'POST',
                body: JSON.stringify(id),
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
            });
            const data = await response.json();
            investments.clientId = data.client.id;
            investments.investorId = data.investor.id;
            investments.completed = data.completed == false ? "0" : "1";
            investments.insurance = data.insurance;
            investments.interestRate = data.interestRate;
            investments.moneyInvested = data.moneyInvested;
            investments.description = data.description;
            investments.monthsPayment = data.monthsPayment;
            investments.dayOfInvoice = data.dayOfInvoice;
            investments.dateInvested = new Date(data.dateInvested);
            investments.finished = data.finished;


            let req = {
                type: -1,
                investment: id,
                paid: -1,
                issuedDateFrom: "2018-03-23T23:00:00.000Z",
                issuedDateTo: "2099-03-24T22:59:59.000Z"
            };
            response = await fetch('invoice/getinvoices', {
                method: 'POST',
                body: JSON.stringify(req),
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
            });
            let res = await response.json();
            invoicesToShowTMP = res.invoices;
        }
        this.setState({ invoicesToShow: invoicesToShowTMP, investmentId: id, investments: investments });
    }

    render() {
        return (
            this.props.show == true ? (
                <div>
                    <Modal handleClose={this.hideModal} title={this.state.ModalTitle} show={this.state.showModal} cotent={this.state.ModalContent} />
                    <div className="investmentContainer">
                        <form>
                            <div className="row">

                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label htmlFor="investor">Investor</label>
                                        <select disabled={this.props.disabled} value={this.state.investments.investorId} className="form-control" id="investorId" onChange={this.handleInputChange.bind(this)}>
                                            <option key={-1}></option>
                                            {this.props.investors.map(inv =>
                                                <option key={inv.id} value={inv.id}>{inv.name}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>

                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label htmlFor="client">Client</label>
                                        <select disabled={this.props.disabled} value={this.state.investments.clientId} className="form-control" id="clientId" onChange={this.handleInputChange.bind(this)}>
                                            <option key={-1}></option>
                                            {this.props.clients.map(inv =>
                                                <option key={inv.id} value={inv.id}>{inv.name}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>

                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label htmlFor="MoneyInvested">Investment Name</label>
                                        <input disabled={this.props.disabled} value={this.state.investments.description} type="text" className="form-control" id="description" onChange={this.handleInputChange.bind(this)} />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label htmlFor="MoneyInvested">Money Invested</label>
                                        <input disabled={this.props.disabled} value={this.state.investments.moneyInvested} type="number" className="form-control" id="moneyInvested" placeholder="10000" onChange={this.handleInputChange.bind(this)} />
                                    </div>
                                </div>

                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label htmlFor="investor">Insurance</label>
                                        <input disabled={this.props.disabled} value={this.state.investments.insurance} type="text" className="form-control" id="insurance" onChange={this.handleInputChange.bind(this)} />
                                    </div>
                                </div>

                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label htmlFor="InterestRate">Interest Rate</label>
                                        <input disabled={this.props.disabled} value={this.state.investments.interestRate} type="number" className="form-control" id="interestRate" placeholder="3.5" onChange={this.handleInputChange.bind(this)} />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label htmlFor="type">Date Invested</label>
                                        <div>
                                            <DatePicker disabled={this.props.disabled} selected={this.state.investments.dateInvested} onChange={date => {
                                                this.handleChangeDate(date)
                                            }}
                                                dateFormat="dd.MM.yyyy"
                                                id="dateInvested" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label htmlFor="LoanMaturity">Day of Invoice </label>
                                        <input disabled={this.props.disabled} value={this.state.investments.dayOfInvoice} type="text" className="form-control" id="dayOfInvoice" onChange={this.handleInputChange.bind(this)} />
                                    </div>
                                </div>

                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label htmlFor="investor">Number Of Months To Repay Loan</label>
                                        <input disabled={this.props.disabled} value={this.state.investments.monthsPayment} type="text" className="form-control" id="monthsPayment" onChange={this.handleInputChange.bind(this)} />
                                    </div>
                                </div>

                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label htmlFor="investor">Finished</label>
                                        <select disabled={this.props.disabled} value={this.state.investments.finished} className="form-control" id="finished" onChange={this.handleInputChange.bind(this)}>
                                            <option key={0} value={false}>No</option>
                                            <option key={1} value={true}>Yes</option>
                                        </select>
                                    </div>
                                </div>


                            </div>
                            <div className="col-sm-12 investmentpartnercontainer">
                                {this.state.investmentId != -1 ?
                                    <InvestmentPartnerContainer allPartners={this.props.allPartners} investmentId={this.state.investmentId} disabled={this.props.disabled} /> : null
                                }
                            </div>

                            <div className="col-sm-12 investmentInvoices">
                                {this.props.disabled ?
                                    <InvoicesDataTable invoices={this.state.invoicesToShow} /> : null
                                }
                            </div>


                            <div className="col-sm-12 investmentpartnercontainer text-right">
                                <button onClick={this.props.closeInvestContainerwModal} type="submit" className="btn btn-default mr-2">Close</button>
                                <button disabled={this.state.updateLoading} type="submit" onClick={(e) => this.saveInvestment(e)} className="btn btn-success" hidden={this.props.disabled}>
                                    {this.state.updateLoading == true ? <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span> : null}
                                    {this.state.investmentId != -1 ? "Update Investment" : "Add New Investment"}</button>
                            </div>
                        </form>
                    </div ></div>)
                : null
        );
    }
}