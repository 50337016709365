import React, { Component } from 'react';
import './../investment.css';
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faBan } from '@fortawesome/free-solid-svg-icons'
import DataTable from 'react-data-table-component';
import { ExpandedComponent } from './../InvoiceList';
export class InvoicesDataTable extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const conditionalRowStyles = [
            {
                when: row => row.invoiceType == 1,
                style: {
                    backgroundColor: 'rgba(220, 220, 220, 0.8)',
                    color: 'black',
                }
            }, {
                when: row => row.invoiceType == 0,
                style: {
                    borderTop: '2px solid black',
                    color: 'black',
                }
            }
        ];

        const columns = [
            {
                name: 'Investment',
                selector: (row, i) => {
                    return row.investment.client.name + ": " + row.investment.description
                },
                sortable: true,
                wrap: true
            },
            {
                name: 'Invoice Nr',
                selector: 'invoiceNr',
                sortable: true,
                wrap: true
            },
            {
                name: 'Issuer',
                selector: 'companyIssuer.name',
                sortable: true,
                wrap: true
            },
            {
                name: 'Date Issued',
                selector: 'invoiceCreatedDate',
                format: (row, i) => {
                    let d = new Date(row.invoiceCreatedDate);
                    return d.getDate() + "." + (parseInt(d.getMonth()) + 1) + "." + d.getFullYear();
                },
                sortable: true,
                wrap: true,
                width: "100px"
            },
            {
                name: 'Due Date',
                selector: 'dueDate',
                format: (row, i) => {
                    let d = new Date(row.dueDate);
                    return d.getDate() + "." + (parseInt(d.getMonth()) + 1) + "." + d.getFullYear();
                },
                sortable: true,
                wrap: true,
                width: "100px"
            },
            {
                name: 'Invoice Value',
                selector: 'invoiceValue',
                format: (row, i) => {
                    return row.invoiceValue.toLocaleString('de-DE', {
                        style: 'currency',
                        currency: 'EUR',
                    });
                },
                sortable: true,
                wrap: true
            },
            {
                name: 'Type',
                selector: 'invoiceType',
                sortable: true,
                format: (row, i) => {
                    return row.invoiceType == 0 ? "Invoice" : "Faktura"
                }, wrap: true,
                width: "100px"
            },
            {
                name: "Paid",
                cell: (inv) => {
                    const paidIcon = inv.paid == true ? <FontAwesomeIcon icon={faCheck} color={"#28a745"} /> : <FontAwesomeIcon icon={faBan} color={"#dc3545"} />;
                    const dd = new Date(inv.paidDate);
                    const paidD = inv.paid == true ? <span style={{ "color": "#28a745", "marginRight": "2px" }}>{dd.getDate() + "." + (dd.getMonth() + 1) + "." + dd.getFullYear()}</span> : null;
                    return <div>{paidD}  {paidIcon}</div>;
                },
                ignoreRowClick: true,
                allowOverflow: true,
                button: false,
                right: true
            }];

        return (
            <div>
                {
                    this.props.loading == true
                        ?
                        <p><em>Loading data...</em></p>
                        :
                        <div className="mt-3">
                            <h3>Investment Invoices</h3>
                            <DataTable
                                columns={columns}
                                data={this.props.invoices}
                                pagination={true}
                                paginationPerPage={50}
                                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                                highlightOnHover
                                defaultSortAsc={false}
                                defaultSortField="type"
                                noHeader={true}
                                conditionalRowStyles={conditionalRowStyles}
                                expandableRows
                                expandableRowDisabled={row => row.fakture.length == 0}
                                expandableRowsComponent={<ExpandedComponent edit={false}/>}
                            />
                        </div>
                }
            </div >
        );
    }
}
